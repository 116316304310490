import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"text-transform":"capitalize"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_filled = _resolveComponent("upload-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_UploadImageDialog = _resolveComponent("UploadImageDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      class: "avatar-uploader",
      onClick: _ctx.onClickUpload,
      title: _ctx.disableEditStatus ? 'Can not upload design' : 'Click to upload design',
      disabled: _ctx.disableEditStatus,
      style: {"height":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "text",
          disabled: _ctx.disableEditStatus
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_icon, { class: "avatar-uploader-icon" }, {
              default: _withCtx(() => [
                _createVNode(_component_upload_filled)
              ]),
              _: 1
            }),
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.artworkName), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }, 8, ["onClick", "title", "disabled"]),
    _createVNode(_component_UploadImageDialog, {
      ref: "uploadImageDialog",
      onCallbackUploadImage: _ctx.onAfterUploadFile
    }, null, 8, ["onCallbackUploadImage"])
  ], 64))
}