
import { Plus, View, Delete, CopyDocument, Edit } from '@element-plus/icons'
import useUploadRepositories, {
  FileItem,
} from '@/repositories/useUploadRepositories'
import { imageUrl } from '@/utils/image'
import { ref, defineComponent } from 'vue'
import { getFileNameFromUrl } from '@/utils/file'
import { copyToClipboard } from '@/utils/string'
import { useNotification } from '@/composables'
import { validateImageFileUploaded } from '@/utils/file'
import SlotError from '@/components/common/SlotError.vue'
import UploadImageDialog from '@/components/common/UploadImageDialog.vue'
import DeleteDesignConfirmDialog from './DeleteDesignConfirmDialog.vue'

export default defineComponent({
  components: {
    Plus,
    View,
    Delete,
    CopyDocument,
    Edit,
    SlotError,
    UploadImageDialog,
    DeleteDesignConfirmDialog,
  },
  props: {
    setData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
      default: '',
    },
    permission: {
      type: String,
      default: 'all',
    },
    artwork: {
      type: String,
      default: '',
    },
    data: {
      type: String,
      default: 'src',
    },
    accept: {
      type: String,
      default: '*',
    },
    itemIndex: {
      type: Number,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { onUploadImage } = useUploadRepositories()
    const files = ref<any>([])
    files.value.push(props.data)
    const inputFile = ref<HTMLInputElement | null>(null)
    const upload = () => {
      inputFile.value?.click()
    }

    const uploadImageDialog = ref<InstanceType<typeof UploadImageDialog>>()
    const change = () => {
      uploadImageDialog.value?.toggle()
    }

    const views = ref<any>(props.setData[props.data])
    const onChangeFiles = async (e: any) => {
      const file = e.target.files[0]
      const res = (await onUploadImage(file)) as FileItem & {
        status: 500
        error: any
      }
      files.value = []
      files.value.push(res.path)
      emit(
        'callback',
        props.setData,
        files.value,
        props.data,
        'add',
        props.artwork,
        props.itemIndex
      )
    }
    const { success, error, warning } = useNotification()

    const copyToClipboardUrl = (text: string) => {
      copyToClipboard(text)
      success('Copy to clipboard')
    }

    const dialog = ref<boolean>(false)
    const imgView = ref<string>('')
    const view = (path: string) => {
      dialog.value = true
      imgView.value = path
    }

    const onSaveChange = async (data: any) => {
      emit('changeArtwork', {
        path: data.path,
        artwork: props.artwork,
        index: props.itemIndex,
        isApplyForAllVariants: data.isApplyForAllVariants,
      })
    }

    const confirmDelete = (data: any) => {
      emit('changeArtwork', {
        path: '',
        artwork: props.artwork,
        index: props.itemIndex,
        isApplyForAllVariants: data.isApplyForAllVariants ?? true,
      })
    }

    const hoverIndex = ref(-1)
    const onMouse = (index: number) => {
      hoverIndex.value = index
    }
    const onMouseMove = () => {
      hoverIndex.value = -1
    }

    const deleteDesignConfirmDialog =
      ref<InstanceType<typeof DeleteDesignConfirmDialog>>()
    const showModalDeleteConfirm = () => {
      deleteDesignConfirmDialog.value?.toggle()
    }

    return {
      onUploadImage,
      onChangeFiles,
      files,
      upload,
      inputFile,
      view,
      dialog,
      imgView,
      onMouse,
      hoverIndex,
      views,
      onMouseMove,
      imageUrl,
      getFileNameFromUrl,
      copyToClipboardUrl,
      confirmDelete,
      validateImageFileUploaded,
      change,
      uploadImageDialog,
      onSaveChange,
      deleteDesignConfirmDialog,
      showModalDeleteConfirm,
    }
  },
})
