
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import ApplyForAllVariantCheckbox from '@/components/common/checkbox/ApplyForAllVariantCheckbox.vue'
import { defineComponent, ref } from 'vue'
import { WarningFilled } from '@element-plus/icons'
import SaveButton from '@/components/common/buttons/SaveButton.vue'

export default defineComponent({
  components: {
    CloseButton,
    ApplyForAllVariantCheckbox,
    WarningFilled,
    SaveButton,
  },
  props: {},
  setup(props, context) {
    const dialogVisible = ref(false)
    const isApply = ref(true)
    const toggle = () => {
      dialogVisible.value = true
    }

    const onConfirmDelete = () => {
      context.emit('callback', { isApplyForAllVariants: isApply.value })
    }
    return { dialogVisible, toggle, isApply, onConfirmDelete }
  },
})
